import React from "react"
import {
  Banner,
  Works,
  Categories,
  Services,
  ContactUs,
} from "../components/landing"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = props => (
  <Layout>
    <SEO
      title="Home"
      description="First Rate Merchant is one of the best service provider in whole USA. It has has stayed true to its original mission bring educated buyers and sellers together at the time they are ready to do business"
    />
    <Banner />
    <Works />
    <Categories />
    <Services />
    <ContactUs />
  </Layout>
)

export default IndexPage
